import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ManagerApi from 'src/services/managerApi';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function SecondaryBanner() {
    let params = {
        slidesPerView: 2,
        spaceBetween: 40,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        let newBanners = []
        generalInformation.banners.map( (banner) => {
            if(banner.type == 'home') {
                newBanners.push(banner)
            }
        });
        setBanners(newBanners);
    }, []);

    return (
        <div className="secondary-banners">
            <div className="container">
                <Swiper
                    spaceBetween={40}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    pagination
                    breakpoints={{
                        576: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {banners.length > 0 &&
                        banners.map((banner, i) => (
                            <SwiperSlide key={i}>
                                {banner.link === "#" ? (
                                    <div
                                        className="item"
                                    >
                                        <span class="image d-none d-md-block" style={{ backgroundImage: `url(${banner.imageDesktopUrl})` }}></span>
                                        <span class="image d-block d-md-none" style={{ backgroundImage: `url(${banner.imageMobileUrl})` }}></span>
                                    </div>
                                ) : (
                                    <a
                                        className="item"
                                        href={banner.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span class="image d-none d-md-block" style={{ backgroundImage: `url(${banner.imageDesktopUrl})` }}></span>
                                        <span class="image d-block d-md-none" style={{ backgroundImage: `url(${banner.imageMobileUrl})` }}></span>
                                    </a>
                                )}
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    );
    
}