import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme, Slider } from '@material-ui/core';
import { Typography, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/ContactModels';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import CardProperty from 'src/components/AnageImoveis/CardProperty';
import KnowMoreForm from './KnowMoreForm';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from "simple-react-lightbox";

import Maps from 'src/components/AnageImoveis/MapsBlock';
import GoogleMapReact from 'google-map-react';
import { getWhatsappLink } from 'src/utils/functions';

import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';

import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import image7 from 'src/assets/images/o-que-preciso-para-alugar.webp';
import image10 from 'src/assets/images/o-que-preciso-para-alugar-mobile.png';
import image8 from 'src/assets/images/conhecer-bairro-do-imovel.webp';
import image9 from 'src/assets/images/localizacao-imovel-anage.webp'
import home from 'src/assets/images/icons/home.svg';
import map from 'src/assets/images/icons/map.svg';
import whatsapp from 'src/assets/images/icons/whatsapp-white.svg';
import whatsappGreen from 'src/assets/images/icons/whatsapp.svg';
import whatsappBlack from 'src/assets/images/icons/whatsapp-black.svg';
import phone from 'src/assets/images/icons/phone.svg';
import icon1 from 'src/assets/images/icons/icon-area-2.svg';
import icon2 from 'src/assets/images/icons/icon-vagas.svg';
import icon3 from 'src/assets/images/icons/icon-quartos.svg';
import icon4 from 'src/assets/images/icons/mail.svg';
import logo from 'src/assets/images/logo-anage.svg';
import like from 'src/assets/images/icons/like.svg';
import likeFilled from 'src/assets/images/icons/like-filled.svg';

import { formatPrice, getYoutubeEmbedData } from 'src/utils/functions.js';
import { addFavorite, removeFavorite } from 'src/actions';
import slugify from 'slugify';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  
function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
const useStyles = makeStyles(() => ({
    root: {}
}));

function SingleProperty({realEstate}) {

    //console.log('realEstate');
    //console.log(realEstate);

    // verifica se é lançamento ou select
    if (realEstate.origin && realEstate.codigoEmp != "") {
        window.location = 'https://lancamentos.anageimoveis.com.br/?lancamentoid=' + realEstate.codigoEmp;
    } else if (realEstate.tagSelect)
        window.location = 'https://anageselect.com.br/' + realEstate.slug;

    const classes = useStyles();
    const theme = useTheme();
    const { trackEvent } = useAnalytics();

    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const brokerInformation = useSelector((state) => state.interface.brokerInformation);
    
    const [currentTab, setCurrentTab] = useState(0);
    const [showKnowMoreForm, setShowKnowMoreForm] = React.useState(false);
    const [showStorePhone, setShowStorePhone] = React.useState(false);
    const [realEstateBuildings, setRealEstateBuildings] = React.useState([]);
    const [realEstateRelateds, setRealEstateRelateds] = React.useState([]);

    const [VisitStepOne, setVisitStepOne] = useState(false);
    const [VisitStepTwo, setVisitStepTwo] = useState(false);
    const [VisitStepThree, setVisitStepThree] = useState(false);

    const [mapMarkerSize, setMapMarkerSize] = useState(16);
    

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const interfaceStore = useSelector((state) => state.interface);

    const realEstateApi = new ManagerApi('/realestate');
  
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    const options = {
        buttons: {
          backgroundColor: '#ffffff',
          iconColor: '#1a1818',
          iconPadding: '10px',
          showAutoplayButton: false,
          showCloseButton: true,
          showDownloadButton: false,
          showFullscreenButton: false,
          showNextButton: true,
          showPrevButton: true,
          showThumbnailsButton: false,
          size: '40px'
        },
        settings: {
            transitionSpeed: 1000,
            transitionTimingFunction: "linear"
        },
        thumbnails: {
            thumbnailsSize: ["168px", "95px"],
            thumbnailsOpacity: 0.4
        },
    }

    document.body.classList.add('realestate');

    const generateGallery = () => {

        let count = 0;
        let slides = [];
        
        let tempSlides = [];
        realEstate.gallery.map( (image) => {
            tempSlides.push(
                <a href={image.image} className="image" style={{ backgroundImage: 'url(' + image.image + ')' }}>
                    <img alt="" src={image.image} />
                </a>
            );

            if(count == 0 || count == realEstate.gallery.length-1 || count%2==0) {
                slides.push(
                    <SwiperSlide>
                        <div className="image-item">{tempSlides}</div>
                    </SwiperSlide>
                );
                tempSlides = [];
            }
            count++;
        });

        return (    
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <div className="property-gallery">
                        <Swiper
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            breakpoints={{
                                768: {
                                    "slidesPerView": 2,
                                },
                                1300: {
                                    "slidesPerView": 3,
                                },
                                1367: {
                                    "slidesPerView": 4,
                                },
                            }}>
                            {slides}
                        </Swiper>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>        
        )
    }

    const getAddress = () => {
        let address = [];
        
        if(realEstate.status !== 'alugar') {
            return '';
        }

        if(realEstate.address) {
            address.push(realEstate.address)
        }
        if(realEstate.addressNumber) {
            address.push("nº "+realEstate.addressNumber)
        }
        // if(realEstate.addressComplement) {
        //     address.push(realEstate.addressComplement)
        // }
        return address.join(', ');
    }

    const getFullAddress = () => {
        let address = [];
        if(realEstate.address) {
            address.push(realEstate.address)
        }
        if(realEstate.addressNumber) {
            address.push("nº "+realEstate.addressNumber)
        }
        // if(realEstate.addressComplement) {
        //     address.push(realEstate.addressComplement)
        // }
        if(realEstate.neighbourhood) {
            address.push(realEstate.neighbourhood)
        }
        if(realEstate.city) {
            address.push(realEstate.city)
        }
        if(realEstate.state) {
            address.push(realEstate.state)
        }
        return address.join(', ');
    }

    const fetchRealEstateBuildings = () => {
        if(realEstate.building != '') {
            realEstateApi.getList({
                building: realEstate.building,
                status: realEstate.status,
                paginator: {
                    pageItemsPerPage: 6
                }
            }).then((response) => {
                if (response.data.success && response.data.content.length) {
                    
                    let filtered = response.data.content.filter( (value) => {
                        if(value.id == realEstate.id) {
                            return false;
                        }
                        return true;
                    })

                    setRealEstateBuildings(filtered);
                }
            }).catch((error) => {
            }).then(() => {
            });
        }
    }

    const fetchRealEstateRelateds = () => {
        realEstateApi.getList({
            status: realEstate.status,
            category: realEstate.category ? realEstate.category.id : null,
            minPrice: (realEstate.price * 0.90),
            maxPrice: (realEstate.price * 1.10),
            paginator: {
                pageItemsPerPage: 6
            }
        }).then((response) => {
            if (response.data.success && response.data.content.length) {
                
                let filtered = response.data.content.filter( (value) => {
                    if(value.id == realEstate.id) {
                        return false;
                    }
                    return true;
                })

                setRealEstateRelateds(filtered);
            }
        }).catch((error) => {
        }).then(() => {
        });
    }

    const isFavorite = (id) => {
        let found = false;
        for (let i = 0; i < interfaceStore.favorites.length; i++) {
            if (interfaceStore.favorites[i] === id) {
                found = true;
            }
        }
        return found;
    }

    var savedSearch = "";
    if (sessionStorage.getItem('savedSearch') !== null) {
        savedSearch = sessionStorage.getItem('savedSearch');
    }

    useEffect(() => {
        fetchRealEstateBuildings();
        fetchRealEstateRelateds();
    }, []);



    /*
        Vendas Anagê até 650k:
            Botão Fale pelo Whatsapp: enviar para  47 9653-1009 (zoe)
                Olá, gostaria de mais informações sobre o imóvel xxxx 
            Agendar visita: enviar o lead para esse numero com a frase: 
                Olá vim pelo imóvel xxxx e quero agendar uma visita: 47 9741-3736 (SDR Gabi).


        Em imóveis acima de 650k:
            Botão Fale pelo Whatsapp: enviar para  enviar para  47 9653-1009 (zoe)
                Olá, gostaria de mais informações sobre o imóvel xxxx 
            Agendar visita: enviar o lead para esse numero com a frase: 
                Olá vim pelo imóvel xxxx e quero agendar uma visita: 47 9968-0934 (Vitória SDR).
    */

    let linkComprarFaleWhatsapp;
    let linkComprarAgendarWhatsapp;

    if (realEstate.status === 'comprar') {
        
        linkComprarFaleWhatsapp = 'https://wa.me/+554796531009?text=Olá,+gostaria+de+mais+informações+sobre+o+imóvel+' + realEstate.code;

        if (realEstate.priceSale <= 650000.00) {
            linkComprarAgendarWhatsapp = 'https://wa.me/+554797413736?text=Olá,+vim+do+imóvel+código+' + realEstate.code + '+e+quero+agendar+uma+visita.';
        } else {
            linkComprarAgendarWhatsapp = 'https://wa.me/+554799680934?text=Olá,+vim+do+imóvel+código+' + realEstate.code + '+e+quero+agendar+uma+visita.';
        }
    }


    return (
        <Page
            className={classes.root}
            title={realEstate.name}
            description={realEstate.description}
        >
            <div className="property-content">
                <div className="media-content">
                    <Box>
                        <TabPanel value={currentTab} index={0} dir={theme.direction}>
                            {generateGallery()}
                        </TabPanel>
                        <TabPanel value={currentTab} index={1} dir={theme.direction}>
                            <div className="property-video">
                                <iframe
                                    className="player-item"
                                    src={getYoutubeEmbedData(realEstate.video).url}
                                    frameBorder="0"
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "450px"
                                    }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={2} dir={theme.direction}>
                            <div className="property-video">
                                <iframe
                                    className="player-item"
                                    src={realEstate.tour360}
                                    frameBorder="0"
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "450px"
                                    }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={3} dir={theme.direction}>
                            <div className="property-map">
                                
                                {realEstate.status == 'alugar' ? (
                                  <iframe 
                                      src={"https://www.google.com/maps/embed/v1/place?key="+generalInformation.googleMapsKey+"&q="+getFullAddress()}
                                      allowfullscreen 
                                      loading="lazy"
                                      style={{
                                          width: "100%",
                                          height: "445px",
                                          border: "0"
                                      }}
                                  />
                                ) : (
                                  <div style={{ height: '445px', width: '100%' }}>
                                    <GoogleMapReact
                                      bootstrapURLKeys={{ key: generalInformation.googleMapsKey }}
                                      options={{
                                        minZoom: 6,
                                        maxZoom: 16
                                      }}
                                      defaultCenter={ {
                                        lat: parseFloat(realEstate.latitude),
                                        lng: parseFloat(realEstate.longitude)
                                      } } 
                                      defaultZoom={ 16 }
                                      onBoundsChange={(center, zoom) => {
                                        setMapMarkerSize(1.3835 ** zoom);
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: 'rgba(255,0,0,0.5)',
                                          display: 'block',
                                          marginLeft: (mapMarkerSize/2*-1),
                                          marginTop: (mapMarkerSize/2*-1),
                                          width: mapMarkerSize,
                                          height: mapMarkerSize,
                                          borderRadius: '50%',
                                        }}
                                        lat={parseFloat(realEstate.latitude)}
                                        lng={parseFloat(realEstate.longitude)}
                                      />
                                    </GoogleMapReact>
                                  </div>
                                )}

                                <Formik
                                    initialValues={ {address: ''} }
                                    enableReinitialize
                                    // validationSchema={validationSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values , {resetForm, setStatus}) => {
                                        let url = "https://www.google.com/maps/dir/" + values.address + "/" + realEstate.latitude + "," + realEstate.longitude + "/@" + realEstate.latitude + "," + realEstate.longitude + ",15z";
                                        window.open(url);
                                    }}
                                >
                                { ({ handleBlur, handleChange, setFieldValue, values, status }) => (
                                    <FormikForm className="form">
                                        <span className="text text-1">Como chegar?</span>
                                        <div className="form-group">
                                            <label htmlFor="address" className="label-control">Qual seu endereço?</label>
                                            <input type="text" className="form-control" name="address" placeholder="Digite o endereço" value={values.address} onChange={handleChange} />
                                        </div>
                                        <button type="submit" className="btn btn-2">Calcular Rota</button>
                                    </FormikForm>
                                )}
                                </Formik>

                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={4} dir={theme.direction}>
                            <div className="property-map">
                                <iframe 
                                    src={"https://www.google.com/maps/embed/v1/streetview?key="+generalInformation.googleMapsKey+"&location=" + realEstate.latitude + "," + realEstate.longitude }
                                    allowfullscreen 
                                    loading="lazy"
                                    style={{
                                        width: "100%",
                                        height: "445px",
                                        border: "0"
                                    }}
                                />
                            </div>
                        </TabPanel>
                    </Box>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={currentTab}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="Fotos" className="photo" />
                                        <Tab label="Vídeo" className="video" style={!realEstate.video ? {display: 'none'} : {}} />
                                        <Tab label="Tour Virtual" className="tour" style={!realEstate.tour360 ? {display: 'none'} : {}} />
                                        <Tab label="Mapa" className="map" style={ realEstate.status != 'alugar' && (!realEstate.latitude || !realEstate.longitude) ? {display: 'none'} : {}} />
                                        <Tab label="Street View" className="street" style={!realEstate.latitude || !realEstate.longitude ? {display: 'none'} : {}} />
                                    </Tabs>
                                </AppBar>
                            </div>
                            <div className="col-md-3">
                                <div className="action-favorite">
                                    <button type="button" className="btn btn-favorite" onClick={ () => { if(isFavorite(realEstate.id)) { dispatch(removeFavorite(realEstate.id)) } else { dispatch(addFavorite(realEstate.id)) } }} >
                                        <div className="icons">
                                            { isFavorite(realEstate.id) ? (
                                                <img alt="" src={likeFilled} className="icon filled"/>
                                            ) : (
                                                <img alt="" src={like} className="icon linear"/>
                                            )}
                                        </div>
                                        FAVORITAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="black-band">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-lg-7">
                                <div className="block-info">
                                    <div className="text text-1 text-interest show-mobile">Tem interesse? Fale conosco</div>
                                    <div className="info">
                                        <h4 className="title title-4">
                                            {realEstate.category.name} {realEstate.building ? " - " + realEstate.building : ''}
                                        </h4>
                                        <ul className="list">
                                            <li>
                                                <img alt="" src={icon3} className="icon"></img>
                                                <span>{realEstate.bedrooms} quartos</span>
                                            </li>
                                            <li>
                                                <img alt="" src={icon2} className="icon"></img>
                                                <span>{realEstate.parkingSpaces} vagas</span>
                                            </li>
                                            <li>
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span>{Number.parseFloat(realEstate.area).toFixed(0)} m²</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="info">
                                        <h5 className="text text-5">
                                            Imóvel para {realEstate.status}
                                        </h5>
                                        <div className="title title-4 title-yellow">
                                            {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                                <>
                                                    {/* <span className="priceOld">{formatPrice(realEstate.priceSale)}</span> */}
                                                    <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-5">

                                <div className="block-info justify-content-end">
                                    {!brokerInformation && realEstate.status == 'alugar' && (
                                        <>
                                            <a href={getWhatsappLink("47996531009")} 
                                                target="_blank" 
                                                className="btn new-whatsapp"
                                                onClick={() => { trackEvent('Clique no botão', 'Whatsapp '+realEstate.status, '47996531009') }}
                                            >
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                    <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                </svg>
                                                Fale pelo WhatsApp
                                            </a>
                                        </>
                                    )}

                                    {realEstate.status == 'alugar' && (
                                        <Link 
                                            href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm="+realEstate.code+savedSearch} 
                                            target="_blank" 
                                            className="btn new-visit2"
                                            onClick={() => { trackEvent('Clique no botão', 'Agendar visita '+ realEstate.status) }}    
                                        >
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                            </svg>
                                            Agendar visita
                                        </Link>
                                    )}

                                    {!brokerInformation && realEstate.status == 'comprar' && (
                                        <>
                                            <a href={linkComprarFaleWhatsapp} 
                                                target="_blank" 
                                                className="btn new-whatsapp"
                                                onClick={() => { trackEvent('Clique no botão', 'Fale pelo WhatsApp ' + realEstate.status ) }}
                                            >
                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                    <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                </svg>
                                                Fale pelo WhatsApp
                                            </a>
                                        </>
                                    )}

                                    {realEstate.status == 'comprar' && (
                                        <a 
                                            href={linkComprarAgendarWhatsapp} 
                                            target="_blank" 
                                            className="btn new-visit2" 
                                            onClick={() => { trackEvent('Clique no botão', 'Agendar visita '+ realEstate.status ) }}
                                        >
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                            </svg>
                                            Agendar visita
                                        </a>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="description">
                                    <div className="details">

                                        <div className="row">
                                            <div className="col-md-8">
                                                <h1 className="title title-1 name">
                                                    {realEstate.category.name} {realEstate.status == 'alugar' && realEstate.building ? " - " + realEstate.building : ''}
                                                    <div className="title title-3 location">
                                                        {realEstate.neighbourhood} | {realEstate.city} - {realEstate.state}
                                                    </div>
                                                </h1>
                                            </div>
                                            <div className="col-md-4">
                                                <p className="text text-1">
                                                    Código: {realEstate.code}
                                                </p>
                                            </div>
                                            <div className="col-md-12">
                                                <p className="text text-1">
                                                    {getAddress()}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="highlights">
                                        <div className="highlight">
                                            <img alt="" src={icon3} className="icon"></img>
                                            <span className="text text-1">{realEstate.bedrooms} quartos ({realEstate.suites} suítes)</span>
                                        </div>
                                        <div className="highlight">
                                            <img alt="" src={icon2} className="icon"></img>
                                            <span className="text text-1">{realEstate.parkingSpaces} vagas de garagem</span>
                                        </div>
                                        {realEstate.totalArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área total: {Number.parseFloat(realEstate.totalArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                        {realEstate.privateArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área privativa: {Number.parseFloat(realEstate.privateArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                        {realEstate.landArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área terreno: {Number.parseFloat(realEstate.landArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="show-mobile">
                                        <div className="sidebar">
                                            <div className="block-info">

                                                <p className="text property-type">
                                                    Imóvel para {realEstate.status}
                                                </p>

                                                <p className="title title-2">
                                                    {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                                        <>
                                                            <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                                            <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                                        </>
                                                    )}
                                                </p>
                                                {/* <p className="text text-1">
                                                    Carência de Condomínio
                                                </p> */}

                                                {realEstate.status == 'alugar' && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    Condomínio:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceCondominium)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    IPTU:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceIptu)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    TLU:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceOther)}
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    <span>Total:</span>
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    <span>{formatPrice(parseFloat(realEstate.priceRental) + parseFloat(realEstate.priceCondominium) + parseFloat(realEstate.priceIptu) + parseFloat(realEstate.priceOther) )}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="block-actions">
                                                {showKnowMoreForm ? (
                                                    <>
                                                        <button type="button" className="btn btn-voltar btn-3" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                            <i></i>Voltar
                                                        </button>
                                                        <Fade in={showKnowMoreForm}>
                                                            <div>
                                                                <KnowMoreForm realEstate={realEstate} />
                                                            </div>
                                                        </Fade>
                                                    </>
                                                ) : (
                                                    <>
                                                        {!brokerInformation && realEstate.status == 'alugar' && (
                                                            <>
                                                                <a href={getWhatsappLink("47996531009")} 
                                                                    target="_blank" 
                                                                    className="btn new-whatsapp"
                                                                    onClick={() => { trackEvent('Clique no botão', 'Fale pelo WhatsApp ' + realEstate.status) }}
                                                                >
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                        <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                                    </svg>
                                                                    Fale pelo WhatsApp
                                                                </a>
                                                            </>
                                                        )}

                                                        {!brokerInformation && realEstate.status == 'comprar' && (
                                                            <>
                                                                <a 
                                                                    href={linkComprarFaleWhatsapp} 
                                                                    target="_blank" 
                                                                    className="btn new-whatsapp"
                                                                    onClick={() => { trackEvent('Clique no botão', 'Fale pelo WhatsApp '+ realEstate.status) }}
                                                                >
                                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                        <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                                    </svg>
                                                                    Fale pelo WhatsApp
                                                                </a>
                                                            </>
                                                        )}

                                                        {realEstate.status == 'alugar' && (
                                                            <Link 
                                                                href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm="+realEstate.code+savedSearch} 
                                                                target="_blank" 
                                                                className="btn new-visit"
                                                                onClick={() => { trackEvent('Clique no botão', 'Agendar visita '+ realEstate.status) }} 
                                                            >
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                                    <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                                    <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                                                </svg>
                                                                Agendar visita
                                                            </Link>
                                                        )}

                                                        {realEstate.status == 'comprar' && (
                                                            <a 
                                                                href={linkComprarAgendarWhatsapp} 
                                                                target="_blank" 
                                                                className="btn new-visit" 
                                                                onClick={() => { trackEvent('Clique no botão', 'Agendar visita '+ realEstate.status) }}
                                                            >
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                                    <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                                    <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                                                </svg>
                                                                Agendar visita
                                                            </a>
                                                        )}

                                                        <div class="block-actions-secundary">
                                                            <button type="button"  onClick={ () => setShowStorePhone(true) } className={ showStorePhone ? ("new-link-phone active") : ("new-link-phone") } >
                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                    <path d="M352 320c-32 32-32 64-64 64s-64-32-96-64-64-64-64-96 32-32 64-64-64-128-96-128-96 96-96 96c0 64 65.75 193.75 128 256s192 128 256 128c0 0 96-64 96-96s-96-128-128-96z"></path>
                                                                </svg>
                                                                Telefone das lojas
                                                            </button>
                                                            <button type="button" className="new-link-more" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                                Quero saber mais
                                                            </button>
                                                        </div>

                                                        {showStorePhone ? (
                                                            <div class="phones-box">
                                                                <p>
                                                                    <strong>Anagê Locação:</strong> <a href="tel:47 3025-3000">47 3025-3000</a> <br />
                                                                    R. Max Colin, 301 - Centro
                                                                </p>
                                                                <p>
                                                                    <strong>Anagê Vendas e Locação:</strong> <a href="tel:47 3025-3001">47 3025-3001</a> <br />
                                                                    R. Blumenau, 3033 - Santo Antônio
                                                                </p>
                                                            </div>
                                                        ) : ''} 


                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="shared-block">
                                            <div className="shared">
                                                <span className="text text-6">Compartilhar Imóvel</span>
                                                <ul className="list">
                                                    <li>
                                                        <a href="#">
                                                            <img alt="" src={whatsappBlack} className="icon-whatsapp"></img>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img alt="" src={icon4} className="icon"></img>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {realEstate.status == 'alugar' && (
                                                <p className="text text-7">
                                                    * Os valores de condomínio e taxas são aproximados. Além do aluguel, será de responsabilidade do locatário o pagamento do IPTU, condomínio, TLU, seguro contra incêndio, e demais encargos estabelecidos no contrato de locação, conforme artigos 22 e 23 da lei 8.245/91.”
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="info">
                                        <h4 className="title title-3">
                                            Sobre esse imóvel
                                        </h4>
                                        <div className="block-text">
                                            <div dangerouslySetInnerHTML={{ __html: realEstate.description }}></div>
                                        </div>
                                        {/* <div className="features">
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Suíte:</span> 3
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Salas:</span> estar e jantar
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Área de serviço:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>BWC Social:</span> 5
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Cozinha:</span> com armários
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Interfone:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Mobiliado:</span> SemiMobiliado
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Posição:</span> Frente
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="info">
                                        <h4 className="title title-3">
                                            Características do condomínio
                                        </h4>
                                        <div className="features">
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Salão de festas:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Play Ground:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Espaço Fit:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Elevadores:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Hall de entrada:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Salão de jogos:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Piscina:</span> sim
                                                </p>
                                            </div>
                                            <div className="feature">
                                                <p className="text text-1">
                                                    <span>Sauna:</span> sim
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="small-banner" style={{ backgroundImage: 'url(' + image8 + ')' }}>
                                        <a href={ "/joinville/"+(slugify(realEstate.neighbourhood.toLowerCase())) } className="btn btn-1" target="_blank">Conhecer o bairro</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 offset-md-1 hidden-mobile">
                                <div className="sidebar">
                                    <div className="block-info">

                                        <p className="text property-type">
                                            Imóvel para {realEstate.status}
                                        </p>

                                        <p className="title title-2">
                                            {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                                <>
                                                    <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                                    <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                                </>
                                            )}
                                        </p>
                                        {/* <p className="text text-1">
                                            Carência de Condomínio
                                        </p> */}

                                        {realEstate.status == 'alugar' && (
                                            <>
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            Condomínio:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceCondominium)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            IPTU:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceIptu)}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            TLU:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceOther)}
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            <span>Total:</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            <span>{formatPrice(parseFloat(realEstate.priceRental) + parseFloat(realEstate.priceCondominium) + parseFloat(realEstate.priceIptu) + parseFloat(realEstate.priceOther) )}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="block-actions">
                                        {showKnowMoreForm ? (
                                            <>
                                                <button type="button" className="btn btn-voltar btn-3" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                    <i></i>Voltar
                                                </button>
                                                <Fade in={showKnowMoreForm}>
                                                    <div>
                                                        <KnowMoreForm realEstate={realEstate} />
                                                    </div>
                                                </Fade>
                                            </>
                                        ) : (
                                            <>
                                                {!brokerInformation && realEstate.status == 'alugar' && (
                                                    <>

                                                        <a href={getWhatsappLink("47996531009")} 
                                                            target="_blank" 
                                                            className="btn new-whatsapp"
                                                            onClick={() => { trackEvent('Clique no botão', 'Fale pelo WhatsApp ' + realEstate.status) }}
                                                        >
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                            </svg>
                                                            Fale pelo WhatsApp
                                                        </a>
                                                    </>
                                                )}

                                                {!brokerInformation && realEstate.status == 'comprar' && (
                                                    <>
                                                        <a href={linkComprarFaleWhatsapp} 
                                                            target="_blank" 
                                                            className="btn new-whatsapp"
                                                            onClick={() => { trackEvent('Clique no botão', 'Fale pelo WhatsApp ' + realEstate.status) }}
                                                        >
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                <path d="M436.5 74.4c-47.9-48-111.6-74.4-179.5-74.4-139.8 0-253.6 113.8-253.6 253.7 0 44.7 11.7 88.4 33.9 126.8l-36 131.5 134.5-35.3c37.1 20.2 78.8 30.9 121.2 30.9h0.1c0 0 0 0 0 0 139.8 0 253.7-113.8 253.7-253.7 0-67.8-26.4-131.5-74.3-179.5zM257.1 464.8v0c-37.9 0-75-10.2-107.4-29.4l-7.7-4.6-79.8 20.9 21.3-77.8-5-8c-21.2-33.5-32.3-72.3-32.3-112.2 0-116.3 94.6-210.9 211-210.9 56.3 0 109.3 22 149.1 61.8 39.8 39.9 61.7 92.8 61.7 149.2-0.1 116.4-94.7 211-210.9 211zM372.7 306.8c-6.3-3.2-37.5-18.5-43.3-20.6s-10-3.2-14.3 3.2c-4.2 6.3-16.4 20.6-20.1 24.9-3.7 4.2-7.4 4.8-13.7 1.6s-26.8-9.9-51-31.5c-18.8-16.8-31.6-37.6-35.3-43.9s-0.4-9.8 2.8-12.9c2.9-2.8 6.3-7.4 9.5-11.1s4.2-6.3 6.3-10.6c2.1-4.2 1.1-7.9-0.5-11.1s-14.3-34.4-19.5-47.1c-5.1-12.4-10.4-10.7-14.3-10.9-3.7-0.2-7.9-0.2-12.1-0.2s-11.1 1.6-16.9 7.9c-5.8 6.3-22.2 21.7-22.2 52.9s22.7 61.3 25.9 65.6c3.2 4.2 44.7 68.3 108.3 95.7 15.1 6.5 26.9 10.4 36.1 13.4 15.2 4.8 29 4.1 39.9 2.5 12.2-1.8 37.5-15.3 42.8-30.1s5.3-27.5 3.7-30.1c-1.5-2.8-5.7-4.4-12.1-7.6z"></path>
                                                            </svg>
                                                            Fale pelo WhatsApp
                                                        </a>
                                                    </>
                                                )}

                                                {realEstate.status == 'alugar' && (
                                                    <Link 
                                                        href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm="+realEstate.code+savedSearch} 
                                                        target="_blank" 
                                                        className="btn new-visit"
                                                        onClick={() => { trackEvent('Clique no botão', 'Agendar visita '+ realEstate.status) }}
                                                    >
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                            <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                            <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                                        </svg>
                                                        Agendar visita
                                                    </Link>
                                                )}

                                                {realEstate.status == 'comprar' && (
                                                    <a 
                                                        href={linkComprarAgendarWhatsapp} 
                                                        target="_blank" 
                                                        className="btn new-visit"
                                                        onClick={() => { trackEvent('Clique no botão', 'Agendar visita ' + realEstate.status) }}
                                                    >
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="539" height="512" viewBox="0 0 539 512">
                                                            <path d="M432.716 182.16v270.101h-326.633v-270.101h-37.688v288.942c0 10.41 8.436 18.844 18.844 18.844h364.318c10.415 0 18.844-8.429 18.844-18.844v-288.942h-37.686z"></path>
                                                            <path d="M282.002 4.787c-7.141-6.381-17.945-6.381-25.093 0l-256.909 229.269 25.094 28.121 244.358-218.076 244.37 218.077 25.088-28.123-256.908-229.269z"></path>
                                                        </svg>
                                                        Agendar visita
                                                    </a>
                                                )}

                                                <div class="block-actions-secundary">
                                                    <button type="button"  onClick={ () => setShowStorePhone(true) } className={ showStorePhone ? ("new-link-phone active") : ("new-link-phone") } >
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                            <path d="M352 320c-32 32-32 64-64 64s-64-32-96-64-64-64-64-96 32-32 64-64-64-128-96-128-96 96-96 96c0 64 65.75 193.75 128 256s192 128 256 128c0 0 96-64 96-96s-96-128-128-96z"></path>
                                                        </svg>
                                                        Telefone das lojas
                                                    </button>
                                                    <button type="button" className="new-link-more" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                        Quero saber mais
                                                    </button>
                                                </div>

                                                {showStorePhone ? (
                                                    <div class="phones-box">
                                                        <p>
                                                            <strong>Anagê Locação:</strong> <a href="tel:47 3025-3000">47 3025-3000</a> <br />
                                                            R. Max Colin, 301 - Centro
                                                        </p>
                                                        <p>
                                                            <strong>Anagê Vendas e Locação:</strong> <a href="tel:47 3025-3001">47 3025-3001</a> <br />
                                                            R. Blumenau, 3033 - Santo Antônio
                                                        </p>
                                                    </div>
                                                ) : ''} 

                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="shared-block">
                                    {/* <div className="shared">
                                        <span className="text text-6">Compartilhar Imóvel</span>
                                        <ul className="list">
                                            <li>
                                                <a href="#">
                                                    <img alt="" src={whatsappBlack} className="icon-whatsapp"></img>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img alt="" src={icon4} className="icon"></img>
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {realEstate.status == 'alugar' && (
                                        <p className="text text-7">
                                            * Os valores de condomínio e taxas são aproximados. Além do aluguel, será de responsabilidade do locatário o pagamento do IPTU, condomínio, TLU, seguro contra incêndio, e demais encargos estabelecidos no contrato de locação, conforme artigos 22 e 23 da lei 8.245/91.”
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {realEstateBuildings && realEstateBuildings.length > 0 && (
                    <div className="property-list featured-properties gray">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            Imóveis no mesmo condomínio
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Swiper
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        navigation
                                        pagination
                                        breakpoints={{
                                            576: {
                                                "slidesPerView": 2,
                                            },
                                            992: {
                                                "slidesPerView": 3,
                                            },
                                        }}
                                    >
                                        {realEstateBuildings.map( (realEstateBuilding) => {
                                            return (
                                                <SwiperSlide>
                                                    <CardProperty realEstate={realEstateBuilding}/>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {realEstateRelateds && realEstateRelateds.length > 0 && (
                    <div className="property-list featured-properties">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            Imóveis Similares
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Swiper
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        navigation
                                        pagination
                                        breakpoints={{
                                            576: {
                                                "slidesPerView": 2,
                                            },
                                            992: {
                                                "slidesPerView": 3,
                                            },
                                        }}
                                    >
                                        {realEstateRelateds.map( (realEstateRelated) => {
                                            return (
                                                <SwiperSlide>
                                                    <CardProperty realEstate={realEstateRelated}/>
                                                </SwiperSlide>
                                            )
                                        })}
                                        <SwiperSlide>
                                            <div className="card card-help">
                                                <div className="block-info">
                                                    <h5 className="title title-2">
                                                        Não encontrou o que procurava?
                                                    </h5>
                                                    <p className="text text-1">
                                                        Nós podemos encontrar um lugar que combina com você!
                                                    </p>
                                                    <RouterLink to="/encontrar-meu-imovel" className="btn btn-2">Quero Ajuda</RouterLink>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="additional-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <img alt="" src={image7} className="image img-fluid hidden-mobile"></img>
                                <img alt="" src={image10} className="image img-fluid show-mobile"></img>
                            </div>
                            <div className="col-md-8 offset-md-1">
                                {realEstate.status == 'comprar' ? (
                                    <div className="description">
                                        <h3 className="title title-3">
                                            O que preciso para comprar esse imóvel?
                                        </h3>
                                        <p className="text text-1">
                                            Aqui, você pode comprar seu imóvel com tranquilidade e segurança. Além da maior carteira de imóveis de Joinville e região, oferecemos orientação de especialistas no mercado imobiliário e assessoria jurídica. Venha fazer o melhor negócio!
                                        </p>
                                        <RouterLink to="/como-comprar" className="btn btn-1">
                                            Quero saber mais
                                        </RouterLink>
                                    </div>
                                ) : (
                                    <div className="description">
                                        <h3 className="title title-3">
                                            O que preciso para alugar esse imóvel?
                                        </h3>
                                        <p className="text text-1">
                                            Envie os documentos de forma digital.<br /> Cada modalidade de fiança exige uma lista de documentos específica.
                                        </p>
                                        <RouterLink to="/como-alugar" className="btn btn-1">
                                            Quero saber mais
                                        </RouterLink>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="visit-dialog">
                {!VisitStepTwo && (
                    <div id="block-visit-1" className="block-visit block-visit-1">
                        <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <h2 className="title title-3">Escolha a data e horário da sua visita</h2>
                        </DialogTitle>
                        <DialogContent>
                            <p className="text text-1">
                                Outubro 2020
                            </p>
                            <form className="form" action="#" method="post">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    navigation
                                >
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">17</p>
                                            <p className="text text-1">Domingo</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">18</p>
                                            <p className="text text-1">Segunda</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">19</p>
                                            <p className="text text-1">Terça</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">20</p>
                                            <p className="text text-1">Quarta</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">21</p>
                                            <p className="text text-1">Quinta</p>
                                        </button>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="form-group normal-group">
                                    <p className="text text-1">Horário</p>
                                    <select className="form-control" id="hourSelect">
                                        <option>11:00</option>
                                    </select>
                                </div>
                                <button type="button" className="btn btn-1" onClick={ () => setVisitStepTwo(!VisitStepTwo) }>Avançar</button>
                            </form>
                        </DialogContent>
                    </div>
                )}
                {VisitStepTwo && (
                    !VisitStepThree && (
                    <div id="block-visit-2" className="block-visit block-visit-2">
                        <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <h2 className="title title-3">Seus Dados</h2>
                        </DialogTitle>
                        <DialogContent>
                            <form className="form" action="#" method="post">
                                <div className="form-group">
                                    <label htmlFor="brokerForm-name" className="label-control">Nome</label>
                                    <input type="text" className="form-control" id="brokerForm-name" name="name" placeholder="Digite seu nome" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brokerForm-email" className="label-control">E-mail</label>
                                    <input type="text" className="form-control" id="brokerForm-email" name="email" placeholder="exemplo@email.com.br" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brokerForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                    <input type="text" className="form-control" id="brokerForm-phone" name="phone" placeholder="(00) 00000-00000" />
                                </div>
                                <div className="custom-control custom-checkbox small-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="privacyPolicy" />
                                    <label className="custom-control-label" htmlFor="privacyPolicy">Ao fornecer meus dados eu concordo em receber comunicações de acordo com a <RouterLink to="/politica-de-privacidade">Política de Privacidade.</RouterLink></label>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <button type="button" className="btn btn-9" 
                                            onClick={() => {
                                                setVisitStepOne(VisitStepOne);
                                                setVisitStepTwo(!VisitStepTwo);
                                            }}
                                        >Voltar</button>
                                    </div>
                                    <div className="col-md-7">
                                        <button type="button" className="btn btn-1" onClick={ () => setVisitStepThree(!VisitStepThree) }>Avançar</button>
                                    </div>
                                </div>
                            </form>
                        </DialogContent>
                    </div>
                ))}
                {VisitStepThree && (
                    <div id="block-visit-3" className="block-visit block-visit-3">
                        <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            
                        </DialogTitle>
                        <DialogContent>
                            <div className="block-success">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img alt="" src={logo} className="img-logo"></img>
                                        <h4 className="title title-14">Pré-agendamento realizado com sucesso!</h4>
                                        <p className="text text-1">
                                            Em breve um profissional entrará em contato para confirmar e dar mais detalhes.
                                        </p>
                                        <button type="button" className="btn btn-1">Finalizar</button>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <h4 className="title title-3">Resumo</h4>
                                        <p className="text text-1">
                                            <span>Nome:</span> Mateus Toignal de Oliveira
                                        </p>
                                        <p className="text text-1">
                                            <span>Data:</span> 18 de outubro
                                        </p>
                                        <p className="text text-1">
                                            <span>Horário:</span> 11:00
                                        </p>
                                        <a href="" className="btn btn-9">Adicionar ao calendário</a>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                )}
            </Dialog>

        </Page>
    );
}

export default SingleProperty;
